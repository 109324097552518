import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { AssignUnassignResponse } from 'state-domains/domain/configuration';
import { ActivityUser } from 'state-domains/domain/project';

import { buildConfigurationProjectUsersUrl } from '../../../network/urls';
import { convertToCamel, sendDeleteRequestWithXSRFToken } from '../../../utils';

export const deleteUserFromProject = (
    projectId: string,
    userId: string,
): Observable<AssignUnassignResponse> => {
    const url = `${buildConfigurationProjectUsersUrl(projectId)}/${userId}`;
    return sendDeleteRequestWithXSRFToken(url).pipe(
        switchMap(({ response }: AjaxResponse<ActivityUser>) => {
            const result = {
                user: convertToCamel<ActivityUser>(response),
                type: 'project' as const,
                payloadType: 'unassign' as const,
                projectId,
            };
            return observableOf(result);
        }),
    );
};
